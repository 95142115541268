// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-administration-administration-js": () => import("./../../../src/pages/administration/administration.js" /* webpackChunkName: "component---src-pages-administration-administration-js" */),
  "component---src-pages-administration-index-mdx": () => import("./../../../src/pages/administration/index.mdx" /* webpackChunkName: "component---src-pages-administration-index-mdx" */),
  "component---src-pages-efficacy-safety-mdx": () => import("./../../../src/pages/efficacy-safety.mdx" /* webpackChunkName: "component---src-pages-efficacy-safety-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-moa-index-mdx": () => import("./../../../src/pages/moa/index.mdx" /* webpackChunkName: "component---src-pages-moa-index-mdx" */),
  "component---src-pages-moa-moa-js": () => import("./../../../src/pages/moa/moa.js" /* webpackChunkName: "component---src-pages-moa-moa-js" */),
  "component---src-pages-patient-support-index-mdx": () => import("./../../../src/pages/patient-support/index.mdx" /* webpackChunkName: "component---src-pages-patient-support-index-mdx" */),
  "component---src-pages-patient-support-patient-support-js": () => import("./../../../src/pages/patient-support/patient-support.js" /* webpackChunkName: "component---src-pages-patient-support-patient-support-js" */),
  "component---src-pages-severe-neutropenia-mdx": () => import("./../../../src/pages/severe-neutropenia.mdx" /* webpackChunkName: "component---src-pages-severe-neutropenia-mdx" */),
  "component---src-pages-sitemap-mdx": () => import("./../../../src/pages/sitemap.mdx" /* webpackChunkName: "component---src-pages-sitemap-mdx" */),
  "component---src-pages-study-design-mdx": () => import("./../../../src/pages/study-design.mdx" /* webpackChunkName: "component---src-pages-study-design-mdx" */)
}

