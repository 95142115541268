import {
  createContext,
  default as React,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { useInView } from "react-intersection-observer";

/**
 * InViewElements, InViewElement
 *
 * A collection of components to know which ones are in view,
 * by what degree, and a way to handle that data.
 *
 *
 * <InViewElements>
 *
 *   <InViewElement>
 *     <h2>Heading One</h2>
 *   </InViewElement>
 *
 *   <InViewElement>
 *     <h2>Heading Two</h2>
 *   </InViewElement>
 *
 *   <InViewElement>
 *     <h2>Heading Three</h2>
 *   </InViewElement>
 *
 * </InViewElements />
 */
const StateContext = createContext(null);
const useInViewState = () => useContext(StateContext); // eslint-disable-line

export const InViewElements = ({ children }) => {
  const initialState = {
    active: null,
    ratios: {},
  };

  function handleRatios(state, action) {
    return {
      ...state.ratios,
      [action.payload.id]: action.payload.ratio,
    };
  }

  function handleElementMostInView(state, action) {
    const ratios = handleRatios({ ...state }, action);
    const ratioIds = Object.keys(ratios);

    if (ratioIds.length > 0) {
      const ratioNums = ratioIds.map((id) => ratios[id]);

      // Get the largest ratio to figure out
      // what element is "active" aka most in view.
      const maxRatio = Math.max.apply(null, ratioNums);

      // aka no active ratio
      if (maxRatio === 0) {
        return null;
      }

      // Now go re-lookup what ID had this ratio.
      const active = ratioIds.find((id) => ratios[id] === maxRatio);

      return active;
    }

    // default when no entries have been added.
    return null;
  }

  const reducer = (state, action) => {
    switch (action.type) {
      case "setElementInViewRatio":
        return {
          ratios: handleRatios(state, action),
          active: handleElementMostInView(state, action),
        };
      default:
        return state;
    }
  };

  return (
    <StateContext.Provider value={useReducer(reducer, initialState)}>
      {children}
    </StateContext.Provider>
  );
};

export function InViewElement({ children, as = "div", id }) {
  const [, dispatch] = useInViewState();
  const [elementRatio, setElementRatio] = useState(0);
  const [ref, inView, entry] = useInView();

  const entryRatio = entry ? entry.intersectionRatio : 0;

  useEffect(() => {
    if (entryRatio !== elementRatio) {
      setElementRatio(entryRatio);

      dispatch({
        type: "setElementInViewRatio",
        payload: {
          id: id,
          ratio: entryRatio,
        },
      });
    }
  }, [elementRatio, inView]); // eslint-disable-line

  return (
    <div id={id} as={as} ref={ref}>
      {children}
    </div>
  );
}

/*
  Render Prop function to use state.
*/
export function InViewElementsState({ children }) {
  const [state] = useInViewState();

  return children(state);
}
