import React, { createContext, useContext, useEffect, useReducer } from "react";
import { useInView } from "react-intersection-observer";
import useDimensions from "react-use-dimensions";
import "./styles.scss";

/**
 * Tray, TrayTab, TrayContent
 *
 * A collection of components to be composed for when
 * some content should be fixed to the bottom of the
 * viewport and then rendered "normally" once the user
 * scrolls to it.
 *
 * CSS classes consumer styling
 *
 * .tray & #tray ID for anchoring
 * .tray-tab & .tray-tab--fixed (when fixed)
 * .tray-content
 */

const StateContext = createContext(null);
const useTrayState = () => useContext(StateContext); // eslint-disable-line

export const Tray = ({ children }) => {
  const initialState = {
    tabIsFixed: false,
    tabHeight: null,
    trayHeight: null,
    threshold: 0,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "setTabIsFixed":
        return {
          ...state,
          tabIsFixed: action.payload,
        };
      case "setTabHeight":
        return {
          ...state,
          tabHeight: action.payload,
        };
      case "setTrayHeight":
        return {
          ...state,
          trayHeight: action.payload,
        };
      case "setThreshold":
        return {
          ...state,
          threshold: action.payload,
        };
      default:
        return state;
    }
  };

  return (
    <StateContext.Provider value={useReducer(reducer, initialState)}>
      <TrayContainer>{children}</TrayContainer>
    </StateContext.Provider>
  );
};

function TrayContainer({ children }) {
  const [{ trayHeight, tabHeight, threshold }, dispatch] = useTrayState();
  const [ref, { height }] = useDimensions();

  useEffect(() => {
    if (height !== trayHeight) {
      dispatch({
        type: "setTrayHeight",
        payload: height,
      });
    }
  });

  useEffect(() => {
    // Calculate the threshold of when the TrayTab should
    // no longer be fixed.

    if (trayHeight && tabHeight) {
      const calc = tabHeight / trayHeight;
      const value = calc <= 1 ? calc : 1;

      if (value !== threshold) {
        // new threshold
        dispatch({
          type: "setThreshold",
          payload: value,
        });
      }
    }
  });

  return (
    <div className="tray" id="tray" ref={ref}>
      {children}
    </div>
  );
}

export function TrayTab({ children }) {
  const [state, dispatch] = useTrayState();
  const { tabIsFixed, tabHeight } = state;
  const [ref, { height }] = useDimensions();

  useEffect(() => {
    if (height !== tabHeight) {
      dispatch({
        type: "setTabHeight",
        payload: height, // Used to offset the tray container and tray content
      });
    }
  }, [height]); // eslint-disable-line

  return (
    <div className={`tray-tab ${tabIsFixed && "tray-tab--fixed"}`} ref={ref}>
      {children}
    </div>
  );
}

export function TrayContent({ children }) {
  const [{ tabHeight, threshold }, dispatch] = useTrayState();
  const [ref, inView] = useInView({
    threshold,
  });

  useEffect(() => {
    dispatch({
      type: "setTabIsFixed",
      payload: !inView, // if in view, don't fix position the tray tab
    });
  }, [inView]); // eslint-disable-line

  return (
    <div
      ref={ref}
      style={{
        paddingTop: tabHeight,
      }}
    >
      {children}
    </div>
  );
}

/*
  Render Prop function to use is fixed state.

  eg:

  <TrayIsFixed>
    {fixed => (
      <div>
        IsFixed? {fixed ? 'yep' : 'nope'}
      </div>
    )}
  </TrayIsFixed>
*/
export function TrayIsFixed({ children }) {
  const [{ tabIsFixed }] = useTrayState();

  return children(tabIsFixed);
}
