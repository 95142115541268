import { Breadcrumb } from "@reusable";
import React from "react";
import "./styles.scss";

export default function PageHeader({
  title,
  subtitle,
  children,
  navTitle,
  breadcrumbTitle,
  description,
  background,
  ...rest
}) {
  background = background || rest.pageHeaderBackground || "purple";

  return (
    <div className="page-header-wrapper">
      <header
        aria-labelledby="page-title"
        className={`page-header ${background}`}
      >
        <div className="container animate-on-enter-1">
          {breadcrumbTitle && <Breadcrumb title={breadcrumbTitle} />}
          {title ? (
            <>
              <h1
                id="page-title"
                className="page-header-title animate-on-enter-1"
              >
                <span dangerouslySetInnerHTML={{ __html: title }} />
                {subtitle && (
                  <span className="page-header-subtitle">{subtitle}</span>
                )}
              </h1>
              <p
                className="page-header-description"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </>
          ) : (
            <>{children}</>
          )}
        </div>
      </header>
    </div>
  );
}
