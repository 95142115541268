// Reusable on brand colors.
const COLORS = {
  BLUE: "#588ab5",
  YELLOW: "#ffb81c",
  PURPLE: "#87189d",
  GRAY: {
    LIGHT: "#f2f2f2",
    MEDIUM: "#e2e2e2",
    DARK: "#444444",
  },
};

export default COLORS;
