import React from "react";
import "./styles.scss";

export default function Border({ children, alt, ...props }) {
  const dotsPlacement = alt ? "right" : "left";

  return (
    <div {...props} className={`border border--dots-${dotsPlacement}`}>
      <div className="border-dots">
        <div className="border-dot"></div>
        <div className="border-dot"></div>
        <div className="border-dot"></div>
      </div>
    </div>
  );
}
