import React from "react";

export default function Logo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="118"
      height="99"
      viewBox="0 0 118 99"
    >
      <defs>
        <path id="path-1" d="M0 0L10 0 10 14 0 14z"></path>
        <path
          id="path-3"
          d="M0 0.7376L116.8 0.7376 116.8 33.9996 0 33.9996z"
        ></path>
        <path
          id="path-5"
          d="M0.714780853 0.679L13.7325375 0.679 13.7325375 13.6435 0.714780853 13.6435z"
        ></path>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-129 -286)">
          <circle cx="187" cy="-334" r="738"></circle>
          <g transform="translate(20 163)">
            <g transform="translate(92 219)">
              <rect
                width="150"
                height="44"
                x="0"
                y="0"
                fill="#FFB81C"
                rx="22"
              ></rect>
              <g transform="translate(119 15)">
                <mask id="mask-2" fill="#fff">
                  <use xlinkHref="#path-1"></use>
                </mask>
                <path
                  fill="#87189D"
                  d="M1.539 14c-.425 0-.849-.16-1.153-.47-.563-.579-.503-1.461.133-1.972l5.638-4.532L.581 2.431C-.048 1.913-.095 1.03.476.46c.57-.572 1.542-.615 2.172-.096l6.848 5.641c.323.267.506.647.504 1.044a1.345 1.345 0 01-.519 1.037l-6.924 5.564c-.292.235-.656.351-1.018.351"
                  mask="url(#mask-2)"
                ></path>
              </g>
              <text
                fill="#87189D"
                fontFamily="Helvetica"
                fontSize="16"
                fontWeight="normal"
                letterSpacing="0.04"
              >
                <tspan x="18" y="24">
                  Enroll Now
                </tspan>
              </text>
            </g>
            <text
              fill="#FFF"
              fontFamily="Helvetica"
              fontSize="18"
              fontWeight="normal"
              letterSpacing="0.045"
            >
              <tspan x="0" y="118">
                Seamless access and reimbursement{" "}
              </tspan>
              <tspan x="15.96" y="139">
                support for you and your patients.
              </tspan>
            </text>
          </g>
          <path fill="#000" fillOpacity="0.7" d="M0 0H375V887H0z"></path>
          <g transform="translate(20 237)">
            <rect
              width="334"
              height="412"
              x="0"
              y="0"
              fill="#FFF"
              rx="20"
            ></rect>
            <g transform="translate(109 49)">
              <g transform="translate(0 12)">
                <g transform="translate(0 52.321)">
                  <mask id="mask-4" fill="#fff">
                    <use xlinkHref="#path-3"></use>
                  </mask>
                  <path
                    fill="#588AB5"
                    d="M76.707 15.954c-4.901 6.28-11.632 10.034-18.007 10.04h-.602c-6.371-.006-13.103-3.76-18.005-10.04C32.03 5.624 26.468.738-.002.738l6.892 8.615c15.259 0 21.322 3.945 27.46 11.809 6.267 8.03 15.142 12.83 23.736 12.838h.622c8.599-.008 17.474-4.807 23.742-12.838 6.137-7.864 11.485-11.81 27.46-11.81L116.8.739c-26.47 0-32.03 4.886-40.094 15.216"
                    mask="url(#mask-4)"
                  ></path>
                </g>
                <path
                  fill="#FFBC1D"
                  d="M72.51 53.136a3.992 3.992 0 012.53-5.066 4.026 4.026 0 015.091 2.523 4.003 4.003 0 01-2.535 5.067 4.018 4.018 0 01-5.087-2.524"
                ></path>
                <path
                  fill="#FFBC1D"
                  d="M38.884 55.641a3.997 3.997 0 01-2.542-5.059 4.016 4.016 0 015.08-2.535 3.994 3.994 0 012.545 5.059 4.014 4.014 0 01-5.083 2.535"
                ></path>
                <path
                  fill="#FFBC1D"
                  d="M67.035 45.858L67 45.836a3.995 3.995 0 01-.98-5.578 4.03 4.03 0 015.597-.976c.02.012.038.027.057.038a3.995 3.995 0 01.961 5.581 4.02 4.02 0 01-5.6.957"
                ></path>
                <path
                  fill="#FFBC1D"
                  d="M43.822 44.868a3.992 3.992 0 01.968-5.578 4.03 4.03 0 015.601.96 3.997 3.997 0 01-.969 5.582 4.029 4.029 0 01-5.6-.964"
                ></path>
                <path
                  fill="#FFBC1D"
                  d="M54.188 39.077a4.014 4.014 0 014.02-4.007c2.216 0 4.02 1.792 4.02 4.003a4.007 4.007 0 01-4.017 4.003 4.008 4.008 0 01-4.023-4"
                ></path>
                <path
                  fill="#FFBC1D"
                  d="M24.72 50.006c-2.656-1.044-3.961-4.033-2.912-6.675 1.049-2.646 4.05-3.942 6.703-2.9a5.145 5.145 0 012.913 6.678 5.172 5.172 0 01-6.703 2.897"
                ></path>
                <path
                  fill="#FFBC1D"
                  d="M85.057 47.056c-1.053-2.642.245-5.634 2.897-6.683 2.654-1.048 5.66.244 6.711 2.886 1.053 2.642-.245 5.634-2.898 6.683a5.168 5.168 0 01-6.71-2.886"
                ></path>
                <path
                  fill="#FFBC1D"
                  d="M30.321 37.658a5.128 5.128 0 01-.088-7.277 5.181 5.181 0 017.307-.088 5.128 5.128 0 01.088 7.278 5.157 5.157 0 01-3.698 1.551 5.169 5.169 0 01-3.609-1.464"
                ></path>
                <path
                  fill="#FFBC1D"
                  d="M78.832 37.533a5.13 5.13 0 01.078-7.278 5.183 5.183 0 017.306.072 5.136 5.136 0 01-.075 7.278 5.156 5.156 0 01-3.619 1.472 5.158 5.158 0 01-3.69-1.544"
                ></path>
                <path
                  fill="#FFBC1D"
                  d="M40.277 28.421a5.134 5.134 0 012.745-6.744 5.174 5.174 0 016.772 2.73 5.14 5.14 0 01-2.745 6.747 5.17 5.17 0 01-6.772-2.733"
                ></path>
                <path
                  fill="#FFBC1D"
                  d="M69.397 31.132c-2.63-1.102-3.866-4.121-2.756-6.74 1.106-2.62 4.138-3.851 6.768-2.745 2.63 1.102 3.866 4.12 2.756 6.74a5.168 5.168 0 01-4.766 3.149c-.666 0-1.347-.13-2.002-.404"
                ></path>
                <path
                  fill="#FFBC1D"
                  d="M53.048 23.759c0-2.844 2.312-5.15 5.164-5.154 2.855 0 5.17 2.302 5.17 5.142.005 2.845-2.307 5.15-5.16 5.15-2.855.005-5.171-2.298-5.174-5.138"
                ></path>
                <path
                  fill="#A9268C"
                  d="M7.881 46.777c-3.384-1.197-5.156-4.902-3.95-8.276 1.202-3.374 4.922-5.136 8.306-3.938 3.388 1.196 5.161 4.902 3.955 8.276-.946 2.657-3.454 4.315-6.133 4.315-.72 0-1.454-.122-2.178-.377"
                ></path>
                <path
                  fill="#A9268C"
                  d="M100.37 42.82c-1.199-3.374.581-7.076 3.968-8.265 3.392-1.19 7.11.58 8.303 3.953 1.195 3.378-.582 7.08-3.973 8.27a6.495 6.495 0 01-2.163.37c-2.683 0-5.194-1.663-6.136-4.328"
                ></path>
                <path
                  fill="#A9268C"
                  d="M14.748 30.163a6.464 6.464 0 01-.868-9.122 6.528 6.528 0 019.164-.866 6.463 6.463 0 01.87 9.122 6.501 6.501 0 01-5.02 2.352 6.512 6.512 0 01-4.145-1.486"
                ></path>
                <path
                  fill="#A9268C"
                  d="M92.663 29.31a6.464 6.464 0 01.85-9.127 6.528 6.528 0 019.164.846 6.461 6.461 0 01-.849 9.127 6.503 6.503 0 01-4.154 1.494 6.505 6.505 0 01-5.01-2.34"
                ></path>
                <path
                  fill="#A9268C"
                  d="M80.735 19.241a6.463 6.463 0 01-2.339-8.864c1.811-3.092 5.796-4.132 8.9-2.329 3.105 1.807 4.15 5.776 2.34 8.863a6.507 6.507 0 01-8.9 2.33"
                ></path>
                <path
                  fill="#A9268C"
                  d="M26.91 16.892c-1.814-3.091-.766-7.06 2.34-8.867 3.1-1.803 7.085-.763 8.9 2.33a6.465 6.465 0 01-2.34 8.863 6.512 6.512 0 01-8.9-2.326"
                ></path>
                <path
                  fill="#A9268C"
                  d="M42.919 8.62c-.628-3.526 1.734-6.889 5.27-7.514 3.538-.626 6.918 1.723 7.546 5.245.627 3.527-1.73 6.889-5.271 7.515a6.552 6.552 0 01-1.145.103c-3.096 0-5.838-2.212-6.4-5.349"
                ></path>
                <g transform="translate(60 .321)">
                  <mask id="mask-6" fill="#fff">
                    <use xlinkHref="#path-5"></use>
                  </mask>
                  <path
                    fill="#A9268C"
                    d="M6.078 13.54C2.541 12.912.183 9.546.818 6.023 1.45 2.497 4.83.151 8.368.78c3.536.628 5.894 3.994 5.262 7.522-.562 3.136-3.307 5.34-6.4 5.34-.379 0-.765-.034-1.152-.103"
                    mask="url(#mask-6)"
                  ></path>
                </g>
              </g>
              <text
                fill="#578ab7"
                fontFamily="Arial"
                fontSize="8"
                fontWeight="bold"
                letterSpacing="0.169"
              >
                <tspan x="100" y="83">
                  TM
                </tspan>
              </text>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
