import { CalloutCard } from "@reusable";
import React from "react";
import { Helmet } from "react-helmet";
import carton from "./Rolvedon_final_fullsize-10b.png";
import freeze from "./freeze.svg";
import fridge from "./fridge.svg";
import shake from "./shake.svg";
import "./styles.scss";
import syringe from "./syringe.svg";
import timer from "./timer.svg";
import trash from "./trash.svg";

export default function StudyDesign() {
  const title = "ROLVEDON® (eflapegrastim-xnst) - Prescription Info For HCPs";
  const description =
    "Discover ROLVEDON® dosing and administration information. Learn how to properly administer a single subcutaneous injection of 13.2 mg per chemo cycle";
  const canonicalUrl = "https://www.rolvedon.com/administration";

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <link rel="canonical" href={canonicalUrl} />
        <meta property="og:url" content={canonicalUrl} />
        <meta name="twitter:url" content={canonicalUrl} />
        <meta property="og:title" content={title} />
        <meta name="twitter:title" content={title} />
        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
        <meta name="twitter:description" content={description} />

        <meta
          name="keywords"
          content="eflapegrastim administration, rolvedon administration, eflapegrastim dosing, rolvedon dosing, eflapegrastim dosage, rolvedon dosage, eflapegrastim, eflapegrastim-xnst injection, rolvedon, rolvedon eflapegrastim-xnst injection"
        />
      </Helmet>
      <div className="container">
        <div className="page-layout">
          <div className="page-layout-content animate-on-enter-2">
            <h2 className="page-layout-heading">Recommended dosage</h2>

            <ul style={{ paddingTop: "2rem" }}>
              <li>
                The recommended dosage of ROLVEDON
                <sup style={{ fontSize: "14px", top: "4px" }}>&reg;</sup> is a
                single subcutaneous injection of 13.2&nbsp;mg administered once
                per chemotherapy cycle
              </li>
              <li>
                Administer approximately 24 hours after cytotoxic chemotherapy
              </li>
              <li>
                Do not administer within the period from 14 days before to 24
                hours after administration of cytotoxic chemotherapy
              </li>
              <li>
                Based on the molecular mass of components, a 13.2&nbsp;mg dose
                of eflapegrastim&#8209;xnst contains 9.6 mg Fc, 0.66 mg flexible
                polyethylene glycol (PEG) linker, and 3.6 mg&ndash;CSF
              </li>
            </ul>

            <section className="admin-steps-container">
              <h2 className="page-layout-heading">Administration</h2>

              <ol className="admin-steps">
                <li>
                  <div className="admin-step-image-container ">
                    <img src={fridge} alt="" />
                  </div>
                  <p>Remove ROLVEDON from the refrigerator</p>
                </li>
                <li>
                  <div className="admin-step-image-container ">
                    <img src={timer} alt="" />
                  </div>
                  <p>
                    Place the sealed blister tray on a clean flat surface for a
                    minimum of 30 minutes to allow the product to reach room
                    temperature
                  </p>
                </li>
                <li>
                  <div className="admin-step-image-container ">
                    <img src={syringe} alt="" />
                  </div>
                  <p>Administer ROLVEDON subcutaneously via a single-dose</p>
                </li>
              </ol>
              <div>
                <img src={carton} alt="a hypodermic needle filled with a dose of rolvedon" />
                <p className="carton-disclaimer">
                  Not made with natural rubber latex
                </p>
              </div>
            </section>

            <section className="admin-warnings-container">
              <ul>
                <li>
                  <img src={trash} alt="" />
                  <p>
                    Discard any prefilled syringe left at room temperature for
                    greater than 12 hours
                  </p>
                </li>
                <li>
                  <img src={shake} alt="" />
                  <p>Do not shake</p>
                </li>
                <li>
                  <img src={freeze} alt="" />
                  <p>If ROLVEDON is accidentally frozen, do not use</p>
                </li>
              </ul>
            </section>

            <p>
              Parenteral drug products should be inspected visually for
              particulate matter and discoloration prior to administration,
              whenever solution and container permit.
            </p>

            <p>
              Do not administer ROLVEDON if discoloration or particulates are
              observed.
            </p>

            <p>Administer the entire contents of the prefilled syringe.</p>

            <p>
              If the patient or caregiver misses a dose of ROLVEDON, instruct
              them to contact their healthcare provider.
            </p>

            <section className="references">
              <h2>Reference:</h2>

              <ol>
                <li>
                  ROLVEDON [package insert]. Lake Forest, IL: Spectrum
                  Pharmaceuticals, Inc.
                </li>
              </ol>
            </section>
          </div>
          <div className="page-layout-aside animate-on-enter-3">
            <CalloutCard
              to="/patient-support"
              buttonLabel="View Patient Support"
              icon="support"
              text="ACCESS4Me® provides access and reimbursement support for your patients"
            />
          </div>
        </div>
      </div>
    </>
  );
}
