import React from "react";

/**
 * <IconPopup />
 *
 * SVG source: https://material.io/resources/icons/?search=plus&icon=add&style=baseline
 */
export default function IconPopup({ size = "24px", ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      fill="currentColor"
      {...rest}
    >
      <defs>
        <path
          id="path-1"
          d="M0.00010952381 0.922909091L22.0734286 0.922909091 22.0734286 22.9088727 0.00010952381 22.9088727z"
        ></path>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g>
          <g>
            <mask id="mask-2" fill="#fff">
              <use xlinkHref="#path-1"></use>
            </mask>
            <path
              fill="#FFF"
              d="M20.704.923H1.37C.612.923 0 1.533 0 2.286v11.957h2.738V3.65h16.597v16.532H8.701v2.727h12.003c.756 0 1.37-.61 1.37-1.364V2.286c0-.752-.614-1.363-1.37-1.363"
              mask="url(#mask-2)"
            ></path>
          </g>
          <path
            fill="#FFF"
            d="M11.173 17.113c0 .724.633 1.312 1.413 1.312S14 17.837 14 17.113v-6.8C14 9.588 13.367 9 12.586 9H5.263c-.78 0-1.413.588-1.413 1.313s.633 1.312 1.413 1.312h3.91L.413 19.76a1.248 1.248 0 000 1.857c.277.256.639.384 1 .384.363 0 .724-.128 1-.384l8.76-8.135v3.632z"
          ></path>
        </g>
      </g>
    </svg>
  );
}
