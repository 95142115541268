import { CalloutCard } from "@reusable";
import React from "react";
import { Helmet } from "react-helmet";
import desktopChart from "./desktop-chart-01-d.svg";
import mobileChart from "./desktop-chart-01-m.svg";
import "./styles.scss";

export default function StudyDesign() {
  const title = "ROLVEDON® (eflapegrastim-xnst) Clinical & Study Trial Data";
  const description =
    "Explore ROLVEDON® clinical trial data. This information could provide valuable insights for optimizing chemotherapy-induced neutropenic patient management";
  const canonicalUrl = "https://www.rolvedon.com/study-design";
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <link rel="canonical" href={canonicalUrl} />
        <meta property="og:url" content={canonicalUrl} />
        <meta name="twitter:url" content={canonicalUrl} />
        <meta property="og:title" content={title} />
        <meta name="twitter:title" content={title} />
        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
        <meta name="twitter:description" content={description} />

        <meta
          name="keywords"
          content="eflapegrastim study design, rolvedon study design, eflapegrastim, eflapegrastim-xnst injection, rolvedon, rolvedon eflapegrastim-xnst injection"
        />
      </Helmet>
      <div className="container">
        <div className="page-layout">
          <div className="page-layout-content animate-on-enter-1">
            <h2 className="page-layout-heading">
              ROLVEDON<sup style={{ fontSize: "14px", top: "2px" }}>&reg;</sup>{" "}
              was studied in 2 Phase III clinical trials (n=643)
              <sup>1-3</sup>
            </h2>
            <ul
              style={{
                marginTop: "2rem",
              }}
            >
              <li>
                Study 1 (n=406) and study 2 (n=237) were identically designed
                Phase III randomized, open-label, noninferiority trials of
                eflapegrastim&#8209;xnst vs pegfilgrastim to prevent
                chemotherapy-induced neutropenia (CIN)<sup>1-3</sup>
              </li>
              <li>
                Studied in patients with early-stage breast cancer receiving
                docetaxel and cyclophosphamide (TC) intravenously every 21 days
                for 4 cycles<sup>1-3</sup>
              </li>
              <li>
                A fixed dose of eflapegrastim&#8209;xnst
                (13.2&nbsp;mg/0.6&nbsp;mL) or pegfilgrastim (6 mg/0.6 mL) was
                administered on day 2 of each cycle after TC chemotherapy
                <sup>1-3</sup>
              </li>
            </ul>

            <div className="study-charts">
              <img src={desktopChart} alt="ROLVEDON® study design" />
              <img src={mobileChart} alt="ROLVEDON® study design" />
            </div>

            <ul>
              <li>
                <strong>Primary Endpoint:</strong> Duration of severe
                neutropenia (DSN) in cycle 1<sup>1-3</sup>
              </li>
              <li>
                <strong>Key Secondary Endpoints:</strong> Time to ANC recovery
                in cycle 1, depth of ANC nadir in cycle 1, incidence of FN in
                cycle 1<sup>1,3</sup>
              </li>
              <li>
                <strong>Additional Secondary Endpoints:</strong> DSN in cycles
                2, 3, and 4; incidence of neutropenic complications<sup>†</sup>{" "}
                during cycle 1; incidence of FN in cycles 2, 3, and 4; RDI of TC
                chemotherapy in cycles 1 to 4, safety<sup>1,3</sup>
              </li>
            </ul>

            <section
              style={{
                fontSize: "0.85rem",
              }}
            >
              <p style={{
                fontSize: "0.85rem",
              }}>
                ANC=absolute neutrophil count; DSN=duration of severe
                neutropenia; FN=febrile neutropenia; RDI=relative dose
                intensity; TC=docetaxel and cyclophosphamide.
              </p>

              <p
                style={{
                  paddingBottom: "1rem",
                  fontSize: "0.85rem",
                }}
              >
                *Standard dose TC.<br></br>
                <sup>†</sup>Neutropenic complications were defined as
                anti-infective use or hospitalizations.
              </p>
            </section>

            <section className="references">
              <h2>References:</h2>
              <ol>
                <li>
                  Schwartzberg LS, Bhat G, Peguero J, et al. Eflapegrastim, a
                  long-acting granulocyte-colony stimulating factor for the
                  management of chemotherapy-induced neutropenia: results of a
                  phase III trial. <em>Oncologist.</em> 2020; 25(8):e1233-e1241.
                </li>
                <li>
                  ROLVEDON [package insert]. Lake Forest, IL: Spectrum
                  Pharmaceuticals, Inc.
                </li>
                <li>
                  Cobb PW, Moon YW, Mezei K, et al. A comparison of
                  eflapegrastim to pegfilgrastim in the management of
                  chemotherapy-induced neutropenia in patients with early-stage
                  breast cancer undergoing cytotoxic chemotherapy (RECOVER): a
                  phase 3 study. <em>Cancer Med.</em> 2020;9(17):6234-6243.
                </li>
              </ol>
            </section>
          </div>
          <div className="page-layout-aside animate-on-enter-2">
            <div>
              <CalloutCard
                to="/efficacy-safety"
                buttonLabel="Explore Efficacy"
                icon="neutropil"
                text="Proven to reduce duration of severe neutropenia"
              />
              <CalloutCard
                to="/efficacy-safety/#safety-profile"
                buttonLabel="Review Safety"
                icon="shield"
                text="ROLVEDON has a proven safety profile"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
