import { Button, IconArrowRight, Link, Modal } from "@reusable";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import rolontisConnect from "./ACCESS4Me-Logo.png";
import flashCard from "./ROLVEDON-Distribution-Flashcard.png";
import computer from "./computer.png";
import computerSVG from "./computer.svg";
import "./styles.scss";
export default function PatientSupport() {
  const title = "ROLVEDON® (eflapegrastim-xnst) Patient Support &amp; Access";
  const description =
    "The ROLVEDON® patient support program could improve patient access. From benefits verification to copay assistance, we're here to assist your patients";
  const canonicalUrl = "https://www.rolvedon.com/patient-support";
  const [showDialog, setShowDialog] = useState(false);

  function dismiss() {
    setShowDialog(false);
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <link rel="canonical" href={canonicalUrl} />
        <meta property="og:url" content={canonicalUrl} />
        <meta name="twitter:url" content={canonicalUrl} />
        <meta property="og:title" content={title} />
        <meta name="twitter:title" content={title} />
        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
        <meta name="twitter:description" content={description} />

        <meta
          name="keywords"
          content="eflapegrastim patient support, rolvedon patient support, eflapegrastim access, rolvedon access, rolvedon connect, eflapegrastim, eflapegrastim-xnst injection, rolvedon, rolvedon eflapegrastim-xnst injection"
        />
      </Helmet>
      <div className="container">
        <div className="page-layout">
          <div className="page-layout-content animate-on-enter-2">
            <h2 className="page-layout-heading support-heading">
              Our mission is to be a trusted resource throughout the access
              process
              <span className="support-subheading">
                Supporting Patient Access
              </span>
            </h2>

            <div className="support-connect-image-container">
              <Link
                affiliated={true}
                href="http://www.Access4Me.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={rolontisConnect} alt="spectrum access 4 me logo. Click here to go to the site" />
              </Link>
            </div>

            <p>
              Our dedicated ACCESS4Me® team is available to provide information
              to support your eligible patients throughout the access
              process—from benefits verification to patient
              assistance—including:
            </p>

            <ul>
              <li>Verification of patient-specific insurance benefits </li>
              <li>Prior authorization assistance </li>
              <li>First-Cycle Patient Support</li>
              <li>Coding and billing information </li>
              <li>Copay assistance for commercially insured patients</li>
              <li>
                Information about financial assistance from independent
                charitable foundations*
              </li>
              <li>
                Free product for eligible uninsured or underinsured patients
              </li>
              <li>Claims Denial Support Program</li>
            </ul>

            <p style={{
                fontSize: "0.85rem",
              }}>
              *Independent foundations have their own eligibility rules and we
              cannot guarantee a foundation will help you. We do not endorse or
              prefer any particular foundation.
            </p>

            <OrderingRolontis />
          </div>
          <div className="page-layout-aside animate-on-enter-4 support-callout-large">
            <SupportCalloutCard />
          </div>
        </div>
      </div>
      <div className="support-portal-container">
        <div className="container">
          <div className="support-portal-inner-container animate-on-enter-3">
            <div className="support-portal-image-container">
              <img src={computer} alt="" />
            </div>
            <div className="support-portal-content">
              <h2>ACCESS4Me® Provider Portal</h2>
              <p>
                The{" "}
                <Link
                  affiliated={true}
                  href="https://www.Access4MePortal.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  online portal
                </Link>{" "}
                is a fast, secure, and convenient way to enroll your patients
                and receive real-time information on insurance approval and
                patient status
                <sup>†</sup>
              </p>

              {showDialog && <ComingSoonModal dismiss={dismiss} />}

              <p className="footnote">
                <sup>†</sup>Requires registration and e-signature setup.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container support-callout-small animate-on-enter-3">
        <SupportCalloutCard />
      </div>
    </>
  );
}

function SupportCalloutCard() {
  const [showDialog, setShowDialog] = useState(false);

  function dismiss() {
    setShowDialog(false);
  }

  return (
    <div className="callout-card">
      <div className="callout-card-icon">
        <img alt="" src={computerSVG} />
      </div>
      <div className="callout-children">
        <p>
          Call{" "}
          <a href="tel:866-582-2737">
            866-582-2737
            <br />
            (866-58-CARES)
          </a>
          <br />
          8:00 <span className="small-caps">AM</span> to 8:00{" "}
          <span className="small-caps">PM</span> (ET)
          <br />
          Monday – Friday
        </p>
      </div>

      <div className="callout-card-button">
        <div className="callout-card-button explore">
          <Button affiliated={true} icon="right" to="http://www.Access4Me.com">
            Explore ACCESS4Me®
          </Button>
        </div>
      </div>
    </div>
  );
}

function OrderingRolontis() {
  const [showDialog, setShowDialog] = useState(false);

  function dismiss() {
    setShowDialog(false);
  }

  return (
    <section className="order-container">
      <h2 className="order-heading">Ordering ROLVEDON</h2>

      <div className="order-card">
        <img
          src={flashCard}
          alt=""
          className="order-card-image"
        />
        <div className="order-card-content">
          <p>Information on purchasing ROLVEDON from authorized distributors</p>

          <Button
            as="a"
            openInNewTab={true}
            href="/pdf/ROLVEDON_Distribution_Card.pdf"
            className="btn"
          >
            <span className="btn__children">
              Download <DownloadIcon />
            </span>
          </Button>

          {showDialog && <ComingSoonModal dismiss={dismiss} />}
        </div>
      </div>
    </section>
  );
}

function DownloadIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="16"
      height="16"
      className="resource-link-icon"
      viewBox="0 0 17 19"
    >
      <defs>
        <path id="path-1" d="M0 0h16.45v3H0z"></path>
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(-196 -11) translate(196 11) translate(0 16)">
          <mask id="mask-2" fill="#fff">
            <use xlinkHref="#path-1"></use>
          </mask>
          <path
            fill="currentColor"
            d="M14.83 0H1.62C.724 0 0 .672 0 1.5S.725 3 1.62 3h13.21c.894 0 1.62-.672 1.62-1.5S15.724 0 14.83 0"
            mask="url(#mask-2)"
          ></path>
        </g>
        <path
          fill="currentColor"
          d="M7.319 13.497c.304.321.732.503 1.18.503.45 0 .878-.182 1.181-.503l5.897-6.222c.6-.633.556-1.617-.095-2.199a1.64 1.64 0 00-2.265.093l-3.114 3.285V1.555C10.103.697 9.385 0 8.5 0S6.896.697 6.896 1.555v6.899L3.784 5.169a1.641 1.641 0 00-2.266-.093 1.524 1.524 0 00-.095 2.199l5.896 6.222z"
          transform="translate(-196 -11) translate(196 11)"
        ></path>
      </g>
    </svg>
  );
}

function ComingSoonModal({ dismiss }) {
  return (
    <Modal Modal handleDismiss={dismiss}>
      <section className="support-modal-content">
        <p>COMING SOON!</p>

        <Button onClick={dismiss}>
          <span>Okay</span>
          <IconArrowRight />
        </Button>
      </section>
    </Modal>
  );
}
