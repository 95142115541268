import { Border, CalloutCard } from "@reusable";
import React from "react";
import { Helmet } from "react-helmet";
import transcytosis from "./SpectrumMOAefla_peg_r2.png";
import moa from "./moa.png";
import "./styles.scss";

export default function MOA() {
  const title = "ROLVEDON® (eflapegrastim-xnst) Mechanism of Action Video";
  const description =
    "Watch our informative ROLVEDON® MOA Video to discover how it binds to the G-CSF receptor, stimulating the production of mature, circulating neutrophils";
  const canonicalUrl = "https://www.rolvedon.com/moa";
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <link rel="canonical" href={canonicalUrl} />
        <meta property="og:url" content={canonicalUrl} />
        <meta name="twitter:url" content={canonicalUrl} />
        <meta property="og:title" content={title} />
        <meta name="twitter:title" content={title} />
        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
        <meta name="twitter:description" content={description} />

        <meta
          name="keywords"
          content="eflapegrastim mechanism of action, rolvedon mechanism of action, eflapegrastim moa, rolvedon moa, eflapegrastim, eflapegrastim-xnst injection, rolvedon, rolvedon eflapegrastim-xnst injection"
        />
      </Helmet>
      <div className="container">
        <div className="page-layout">
          <div className="page-layout-content animate-on-enter-2">
            <section className="moa-intro-container">
              <div className="moa-intro-image-container">
                <img
                  src={moa}
                  alt="a molecular diagram that breaks down each component that makes up rolvedon. The three pieces include: Fc, flexible PEG linker, and G-CSF analog"
                />
              </div>

              <h2 className="page-layout-heading">
                Introducing ROLVEDON<sup className="supFixTop">&reg;</sup>:
              </h2>

              <ul>
                <li>
                  Novel formulation combines a granulocyte colony-stimulating
                  factor (G-CSF) analog with an Fc fragment of human
                  immunoglobulin G4 (IgG4)<sup>2</sup>
                </li>
                <li>
                  Supported by a complete innovator biopharmaceutical
                  development program
                </li>
              </ul>
            </section>

            <section className="safety-sprinkle">
              <h2>Selected Safety Information</h2>
              <h3>Serious Allergic Reactions</h3>
              <ul>
                <li>
                  Serious allergic reactions, including anaphylaxis, can occur
                  in patients receiving rhG-CSF products. Permanently
                  discontinue ROLVEDON in patients who experience serious
                  allergic reactions.
                </li>
              </ul>
            </section>

            <Border />

            <section className="moa-transcytosis-container">
              <h2 className="page-layout-heading">
                FcRn facilitates ROLVEDON transcytosis<sup>3</sup>
              </h2>

              <div className="moa-transcytosis-content">
                <img
                  src={transcytosis}
                  alt="an illustration showing how rolvedon works within the body to combat severe neutropenia"
                />
                <ul>
                  <li>
                    It has been shown in:
                    <ul className="dash-list">
                      <li>
                        <span style={{ fontStyle: "italic" }}>in vitro</span>{" "}
                        studies<sup>2</sup>:
                        <ul>
                          <li>
                            To bind the neonatal Fc receptor (FcRn), which
                            facilitates transcytosis{" "}
                          </li>
                        </ul>
                      </li>

                      <li>
                        <span style={{ fontStyle: "italic" }}>in vivo</span>{" "}
                        animal studies<sup>3</sup>:
                        <ul>
                          <li>
                            To facilitate uptake and retention of G&#8209;CSF in
                            the bone marrow
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <strong>
                      The clinical significance of these findings is unknown
                    </strong>
                  </li>
                </ul>
              </div>
            </section>

            <Border alt />

            <section className="moa-video-container" id="video">
              <h2 className="page-layout-heading">Watch MOA video</h2>

              <figure>
                <a
                  href="https://vimeo.com/742802011"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <iframe
                    className="moa-video"
                    src="https://player.vimeo.com/video/742802011"
                    width="100%"
                    height="auto"
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </a>

                <figcaption
                  style={{
                    margin: "1rem 0",
                  }}
                >
                  <p>
                    Eflapegrastim&#8209;xnst is a recombinant human granulocyte
                    growth factor that binds to{" "}
                    <span style={{ whiteSpace: "nowrap" }}>G-CSF</span>{" "}
                    receptors on myeloid progenitor cells and neutrophils,
                    triggering signaling pathways that control cell
                    differentiation, proliferation, migration, and survival.
                    <sup>2</sup>
                  </p>

                  <p style={{
                fontSize: "0.85rem",
              }}>MOA=mechanism of action.</p>
                </figcaption>
              </figure>
            </section>

            <section
              className="references"
              style={{
                marginTop: "4rem",
              }}
            >
              <h2>References:</h2>
              <ol>
                <li>
                  Schwartzberg LS, Bhat G, Peguero J, et al. Eflapegrastim, a
                  long-acting granulocyte-colony stimulating factor for the
                  management of chemotherapy-induced neutropenia: results of a
                  phase III trial. <em>Oncologist.</em> 2020;25(8):e1233-e1241.
                </li>
                <li>
                  ROLVEDON [package insert]. Lake Forest, IL: Spectrum
                  Pharmaceuticals, Inc.
                </li>
                <li>
                  Barrett J, et al. Eflapegrastim's enhancement of efficacy
                  compared with pegfilgrastim in neutropenic rats supports
                  potential for same-day dosing. Experimental Hematology
                  2020;92:51-61.
                </li>
              </ol>
            </section>
          </div>

          <div className="page-layout-aside animate-on-enter-3">
            <CalloutCard
              to="/administration"
              buttonLabel="Administering ROLVEDON"
              icon="syringe"
              text="ROLVEDON is administered via a single subcutaneous dose"
            />
          </div>
        </div>
      </div>
    </>
  );
}
