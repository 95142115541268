import ContactRep from "@components/contact-rep";
import { Link } from "@reusable";
import { graphql, useStaticQuery } from "gatsby";
import React, { useEffect, useState } from "react";
import "./styles.scss";

export default function NavigationList({
  home = false,
  variation,
  small = false,
}) {
  const [windowWidth, setWindowWidth] = useState(0);
  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const updateWindowWidth = () => {
      setWindowWidth(window.innerWidth);
      setIsDesktop(window.innerWidth > 1000); // Changed to 1000 for consistency with isMobile
      setIsMobile(window.innerWidth <= 1000);
    };

    // Update window width on mount
    updateWindowWidth();

    // Add event listener for window resize
    window.addEventListener("resize", updateWindowWidth);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateWindowWidth);
    };
  }, []);

  // Page data for use in menus
  const data = useStaticQuery(graphql`
    query SiteNavigationQuery {
      navItems: allMdx(
        filter: { frontmatter: { path: { regex: "/" }, order: { gt: 0 } } }
        sort: { fields: frontmatter___order }
      ) {
        nodes {
          frontmatter {
            path
            title
            navTitle
            breadcrumbTitle
          }
        }
      }
    }
  `);
  const items = data.navItems.nodes;
  const classVariation =
    variation === "sitemap" ? "site-nav-list--sitemap" : "";

  return (
    <ul className={"site-nav-list " + classVariation}>
      {home && (
        <li className="site-nav-list-item site-nav-list-item--alt">
          <Link to="/">Home</Link>
        </li>
      )}
      {items.map((item, index) => {
        const { path, breadcrumbTitle, navTitle } = item.frontmatter;
        const isLastItem = index === items.length - 1; // Check if it's the last item

        return (
          <li
            className={`site-nav-list-item ${isLastItem ? "last-item" : ""}`}
            key={path + navTitle}
          >
            <div className="site-nav-list-item-line"></div>
            <Link
              key={path}
              to={path}
              dangerouslySetInnerHTML={{
                __html: small ? breadcrumbTitle : navTitle,
              }}
              style={isLastItem ? { lineHeight: "14px" } : {}} // Add inline style for the last item
            />
          </li>
        );
      })}
      {isMobile && (
        <li className="site-nav-list-item">
          <a
            href="https://www.myrolvedon.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="mobile-only-link"
          >
            Visit Patient Site
          </a>
        </li>
      )}
      {isDesktop && <ContactRep kind="button" />}
    </ul>
  );
}
