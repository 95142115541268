import { Border, CalloutCard } from "@reusable";
import React from "react";
import { Helmet } from "react-helmet";
import calloutSmall from "./callout-small.svg";
import callout from "./callout.svg";
import potential from "./potential.svg";
import riskPNG from "./risk.png";
import risk from "./risk.svg";
import severe from "./severe.svg";
import "./styles.scss";

export default function SevereNeutropenia() {
  const title = "Severe Neutropenia & ROLVEDON® (eflapegrastim-xnst)";
  const description =
    "Learn how your patients could experience a reduction in severe neutropenia and risk of infection with ROLVEDON®. Learn more and view NCCN MGF guidelines";
  const canonicalUrl = "https://www.rolvedon.com/severe-neutropenia";
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <link rel="canonical" href={canonicalUrl} />
        <meta property="og:url" content={canonicalUrl} />
        <meta name="twitter:url" content={canonicalUrl} />
        <meta property="og:title" content={title} />
        <meta name="twitter:title" content={title} />
        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
        <meta name="twitter:description" content={description} />

        <meta
          name="keywords"
          content="severe neutropenia, febrile neutropenia, eflapegrastim, eflapegrastim-xnst injection, rolvedon, rolvedon eflapegrastim-xnst injection"
        />
      </Helmet>
      <div className="container">
        <div className="page-layout">
          <div className="page-layout-content animate-on-enter-2">
            <h2 className="page-layout-heading">
              Severe and febrile neutropenia can cause chemotherapy dose delays,
              reductions, and{" "}
              <span className="do-not-break-text">
                interruptions<sup>1-4</sup>
              </span>
            </h2>

            <div className="severe-list-container">
              <ul className="severe-list">
                <li>
                  Longer duration of severe neutropenia is associated with
                  increased risk of progression to febrile neutropenia
                  <sup>5</sup>
                </li>
                <li>
                  Risk of infection-related hospitalization increases by 28-30%
                  for each additional day a patient has grade 3 or grade 4
                  neutropenia in the first cycle<sup>5</sup>
                </li>
                <li>
                  1 in 5 chemotherapy patients are at risk for severe
                  neutropenia in cycle 1<sup>6</sup>
                </li>
              </ul>
              <div className="severe-risk-container">
                <p
                  style={{
                    fontStyle: "italic",
                    textAlign: "center",
                  }}
                >
                  Incremental risk of hospitalization
                </p>

                <img
                  src={riskPNG}
                  alt="Incremental risk of hospitalization visual"
                />
              </div>
            </div>

            <Border />
            <div className="severe-blocks">
              <div className="severe-block">
                <img src={severe} alt="Various brand art object" />
                <p>
                  Severe neutropenia: absolute neutrophil count (ANC) {"<"}
                  500/µL<sup>7</sup>
                </p>
              </div>
              <div className="severe-block">
                <img
                  src={risk}
                  alt="Incremental risk of hospitalization visual"
                />
                <p>
                  Increased risk of serious infection<sup>7</sup>
                </p>
              </div>
              <div className="severe-block iv-bag">
                <img src={potential} alt="Various brand art object" />
                <p>
                  Potential chemotherapy dose delays, reductions, and
                  interruptions<sup>1,8</sup>
                </p>
              </div>
            </div>
            <Border alt />
          </div>
          <div className="page-layout-aside animate-on-enter-3 ">
            <div className="large-only">
              <SevereCallout />
            </div>
          </div>
        </div>
      </div>
      <div className="full-callout">
        <div className="container">
          <div className="full-callout-inner-container callout-alt-layout animate-on-enter-2">
            <img
              src={callout}
              className="full-callout-image large-only"
              alt="Various brand art object"
            />
            <img
              src={calloutSmall}
              className="full-callout-image small-only"
              alt="Various brand art object"
            />
            <p className="full-callout-text">
              National Comprehensive Cancer Network<sup>®</sup> (NCCN
              <sup>®</sup>) Guidlines<sup>9*</sup>:
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="page-layout">
          <div className="severe-layout-content animate-on-enter-3">
            <h2 className="page-layout-heading text-blue">
              Basis for NCCN Clinical Practice Guidelines in Oncology (NCCN
              Guidelines) Regarding MGF Usage
            </h2>
            <div
              className="center"
              style={{
                marginTop: "2rem",
              }}
            >
              <ul className="checklist">
                <li>
                  Current NCCN guidelines recommend assessing risk of FN before
                  every cycle.
                </li>
                <li>
                  Evaluate the risk of FN following chemotherapy in adult
                  patients with solid tumors and non-myeloid malignancies.{" "}
                </li>
                <li>
                  {" "}
                  Administer primary G-CSF prophylaxis in first and subsequent
                  cycles for patients at high or intermediate FN risk.{" "}
                </li>
                <li>
                  {" "}
                  For reference, see MGF-1 in the NCCN Clinical Practice
                  Guidelines in Oncology (NCCN Guidelines<sup>&reg;</sup>) for
                  Hematopoietic Growth Factors.{" "}
                </li>
              </ul>
            </div>
            {/* <div
              className="center"
              style={{
                marginTop: "2rem",
              }}
            >
              <ul className="checklist">
                <li>Therapeutic efficacy and safety</li>
                <li>Clinical benefit</li>
                <li>Cost saving due to avoidance of FN</li>
              </ul>
            </div> */}

            {/* <p>
              In March 2020, the NCCN temporarily updated recommendations for
              the prophylactic use of G-CSF to minimize the risk of febrile
              neutropenia, helping to keep patients out of the ER and hospitals.
            </p>

            <h3
              className="text-blue text-center"
              style={{
                marginBottom: "1rem",
              }}
            >
              New recommendation:
            </h3>

            <p>
              Change threshold for use of G-CSF with regimens from only high
              risk ({">"}20% risk of febrile neutropenia) to intermediate
              (10%–20% risk of febrile neutropenia) or high risk (for reference
              to usual standard of care, see MGF-1 in the NCCN Guidelines for
              Hematopoietic Growth Factors).
            </p>

            <p style={{ fontStyle: "italic" }}>
              Cautionary statement: Physicians may wish to avoid use of or
              discontinue G-CSF in case of respiratory infection, respiratory
              symptoms, or confirmed or suspected COVID-19 to avoid increase in
              pulmonary inflammation or hypothetical risk of increasing
              inflammatory cytokines associated with adverse outcome.
            </p> */}

            <p
              style={{
                fontSize: "0.85rem",
              }}
            >
              *NCCN makes no warranties of any kind whatsoever regarding their
              content, use, or application and disclaims any responsibility for
              their application or use in any way.
            </p>

            <section className="references">
              <h2>References</h2>

              <ol>
                <li>
                  Lyman GH. Impact of chemotherapy dose intensity on cancer
                  patient outcomes. <em>Natl Compr Canc Netw.</em>{" "}
                  2009;7(1):99-108.
                </li>
                <li>
                  Lyman GH, Dale DC, Friedberg J, Crawford J, Fisher RI.
                  Incidence and predictors of low chemotherapy dose-intensity in
                  aggressive non-Hodgkin’s lymphoma: a nationwide study.{" "}
                  <em>J Clin Oncol.</em> 2004;22(21):4302-4311.
                </li>
                <li>
                  Denduluri N, Patt DA, Wang Y, et al. Dose delays, dose
                  reductions, and relative dose intensity in patients with
                  cancer who received adjuvant or neoadjuvant chemotherapy in
                  community oncology practices. <em>J Natl Compr Canc Netw.</em>{" "}
                  2015;13(11):1383-1393.
                </li>
                <li>
                  Schwartzberg LS, Bhat G, Peguero J, et al. Eflapegrastim, a
                  long-acting granulocyte-colony stimulating factor for the
                  management of chemotherapy-induced neutropenia: results of a
                  phase III trial. <em>Oncologist.</em> 2020;25(8):e1233-e1241.
                </li>
                <li>
                  Li Y, Klippel Z, Shih X, Reiner M, Wang H, Page JH.
                  Relationship between severity and duration of
                  chemotherapy-induced neutropenia and risk of infection among
                  patients with nonmyeloid malignancies.{" "}
                  <em>Support Care Cancer.</em> 2016;24(10):4377-4383.
                </li>
                <li>
                  Lyman GH, Kuderer NM, Crawford J, et al. Predicting individual
                  risk of neutropenic complications in patients receiving cancer
                  chemotherapy.
                  <em>Cancer.</em> 2011;117(9):1917-1927.
                </li>
                <li>
                  Braden CD. Neutropenia. Medscape website.
                  https://emedicine.medscape.com/article/204821-overview#showall.
                  Accessed June 17, 2020.
                </li>
                <li>
                  Crawford J, Dale DC, Kuderer NM, et al. Risk and timing of
                  neutropenic events in adult cancer patients receiving
                  chemotherapy: the results of a prospective nationwide study of
                  oncology practice. <em>J Natl Compr Canc Netw.</em>{" "}
                  2008;6(2):109-118.
                </li>
                <li>
                  Referenced with permission from the National Comprehensive
                  Cancer Network, Inc. © National Comprehensive Cancer Network,
                  Inc. 2020. All rights reserved. Accessed May 5, 2022. To view
                  the most recent and complete version of the recommendations,
                  go online to NCCN.org.
                </li>
              </ol>
            </section>

            <div className="small-only">
              <SevereCallout />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function SevereCallout() {
  const supFixTopStyle = {
    // Define your CSS styles here
    top: "3px",
    fontSize: "14px",
    // ...add more styles as needed
  };

  return (
    <CalloutCard
      to="/study-design"
      buttonLabel="View Trials"
      icon="clipboard"
      text={
        <>
          ROLVEDON<sup style={supFixTopStyle}>&reg;</sup> was studied in 2 Phase
          III clinical trials.
        </>
      }
    />
  );
}
