import { Button, Modal } from "@reusable";
import React, { useState } from "react";
import "./styles.scss";

export default function ContactRep({ kind }) {
  const [showModal, setShowModal] = useState(false);
  const inline = kind === "inline";
  let iframeSrc;
  if (typeof window !== "undefined") {
    if (window.location.href.includes("staging.rolvedon.com")) {
      iframeSrc =
        "https://sppirx.my.salesforce-sites.com/RepRequest";
    } else if (window.location.href.includes("www.rolvedon.com")) {
      iframeSrc = "https://sppirx.my.salesforce-sites.com/RepRequest";
    }
  }
  function dismissModal() {
    setShowModal(false);
  }

  return (
    <>
      {kind === "button" ? (
        <Button onClick={() => setShowModal(true)} icon="popup">
          <span className="rar-btn">Request a Rep</span>
        </Button>
      ) : (
        <button
          to="/contact-a-rep"
          className="contact-a-rep"
          onClick={() => setShowModal(true)}
        >
          Request{inline ? <br /> : " "}a Rep
        </button>
      )}

      {showModal && (
        <Modal className="rep" handleDismiss={dismissModal}>
          <iframe
            src={iframeSrc} 
            style={{ height: "50vh", width: "100%" }}
            title="Request a rep"
          ></iframe>
        </Modal>
      )}
    </>
  );
}
