import { IconArrowRight } from "@reusable";
import { Link } from "gatsby";
import React from "react";
import "./styles.scss";

export default function Breadcrumb({ title }) {
  return (
    <nav aria-label="breadcrumb" className="breadcrumbs">
      <ol className="breadcrumbs-list">
        <li className="breadcrumbs-list-item">
          <Link to="/">Home</Link>

          <IconArrowRight size="18px" />
        </li>
        <li
          className="breadcrumbs-list-item"
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
      </ol>
    </nav>
  );
}
