import { IconArrowRight, IconPopup, Link } from "@reusable";
import React from "react";
import "./styles.scss";

/*
  Looks like a styled button, but can be any element
  with the "as" prop.
*/
export default function Button({
  children,
  as,
  className = "",
  to,
  openInNewTab = false,
  icon = null,
  ...rest
}) {
  const Element = as ? as : "button";

  if (openInNewTab) {
    rest.target = "_blank";
    rest.rel = "noopener noreferrer";
  }

  const Icon = () => {
    if (icon === "right") {
      return <IconArrowRight />;
    }

    if (icon === "popup") {
      return <IconPopup size="20px" />;
    }

    return null;
  };

  if (to) {
    return (
      <Link to={to} data-btn className={`btn ${className}`} {...rest}>
        <span className="btn__children">
          {children}
          <Icon />
        </span>
      </Link>
    );
  }

  return (
    <Element data-btn className={`btn ${className}`} {...rest}>
      <span className="btn__children">
        {children}
        <Icon />
      </span>
    </Element>
  );
}
