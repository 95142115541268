import { Button, COLORS, IconArrowRight, Logo, Modal } from "@reusable";
import { Link as GatsbyLink } from "gatsby";
import React, { useState } from "react";

/*
  The @reusable Link is responsible
  for catching external routes and
  opening a Modal to ask the user
  to confirm that they wish to leave
  this website.
*/
export default function Link({
  to,
  href = "/",
  affiliated = false,
  children,
  ...rest
}) {
  const [showModal, setShowModal] = useState(false);
  const url = to ? to : href;
  const isInternalRoute = url.startsWith("/");

  if (!isInternalRoute) {
    rest.target = "_blank";
    rest.rel = "noopener noreferrer";
  }

  function handleExternalLinkClick(e) {
    e.preventDefault();

    setShowModal(true);
  }

  function dismissModal() {
    setShowModal(false);
  }

  if (isInternalRoute) {
    return (
      <GatsbyLink to={url} {...rest}>
        {children}
      </GatsbyLink>
    );
  }

  const msg = affiliated
    ? "You are about to leave ROLVEDON.com. Do you still want to continue?"
    : "You are now leaving ROLVEDON.com and entering a website that is not controlled by or affiliated with Spectrum Pharmaceuticals, Inc.<br/><br/>This link is provided by Spectrum for informational purposes only. Spectrum accepts no responsibility for the content of this site. Spectrum does not control this site, and the opinions, claims, or comments expressed on this site should not be attributed to Spectrum Pharmaceuticals. ";
  const buttonNoTxt = affiliated ? "No" : "CANCEL";
  const buttonYesTxt = affiliated ? "Yes" : "CONTINUE";
  return (
    <>
      <a href={url} onClick={handleExternalLinkClick} {...rest}>
        {children}
      </a>
      {showModal && (
        <Modal handleDismiss={dismissModal}>
          <section
            style={{
              textAlign: "center",
            }}
          >
            <Logo />
            <p
              style={{
                fontSize: "1.4rem",
                color: COLORS.BLUE,
                fontWeight: "600",
                marginTop: "1rem",
              }}
              dangerouslySetInnerHTML={{ __html: msg }}
            ></p>

            <div>
              <Button
                onClick={dismissModal}
                style={{
                  marginRight: "1.5rem",
                }}
              >
                {buttonNoTxt} <IconArrowRight />
              </Button>
              <a className="btn" as="a" href={url} {...rest}>
                <span className="btn__children">
                  {buttonYesTxt} <IconArrowRight />
                </span>
              </a>
            </div>
          </section>
        </Modal>
      )}
    </>
  );
}
