import ContactRep from "@components/contact-rep";
import { Button } from "@reusable";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import { useInView } from "react-intersection-observer";
import firstSVG from "./first.svg";
import secondSVG from "./second.svg";
import "./styles.scss";
import thirdSVG from "./third.svg";

export default function HomeHero() {
  const { chemobag } = useStaticQuery(graphql`
    query {
      chemobag: file(name: { eq: "chemobagsilo-reg" }) {
        childImageSharp {
          fluid(maxWidth: 720) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  return (
    <div className="hero">
      <div className="hero-outer-container">
        <div className="container">
          <div className="hero-heading-container animate-on-enter-2">
            <Img
              tag="div"
              fluid={chemobag.childImageSharp.fluid}
              className="hero-image animate-enter-from-left"
              alt="A chemotherapy IV bag riding a bike, Rolvedon helps keep chemotherapy treatment moving forward"
            />
            <h1 className="hero-heading">
              <span className="hero-subheading">
                Severe neutropenia can put the brakes on your treatment plan
                <sup>1-4</sup>
              </span>
              ROLVEDON<sup class="supFixTop">®</sup> helps reduce the risk of
              infection
              <span className="hero-subheading-alt">
                to help keep chemotherapy rolling right along<sup>4-7</sup>
              </span>
            </h1>
          </div>
        </div>
        <div className="hero-features-outer-container">
          <div className="container">
            <div className="hero-features">
              <HeroFeature>
                <div className="hero-feature-image">
                  <img src={firstSVG} alt="" />
                </div>
                <p>
                  Proven to reduce duration of severe neutropenia<sup>4-6</sup>
                </p>
                <Button className="eff-btn" to="/efficacy-safety" icon="right">
                  Explore Efficacy
                </Button>
              </HeroFeature>
              <HeroFeature>
                <div className="hero-feature-image">
                  <img src={secondSVG} alt="" />
                </div>
                <p>
                  Severe and febrile neutropenia (FN) can cause chemotherapy
                  dose delays, reductions, and interruptions<sup>1-4</sup>
                </p>
                <Button to="/severe-neutropenia" icon="right">
                  Severe Neutropenia
                </Button>
              </HeroFeature>
              <HeroFeature>
                <div className="hero-feature-image">
                  <img src={thirdSVG} alt="" />
                </div>
                <p>
                  The first long-acting myeloid growth factor (MGF) in over 20
                  years with a unique molecular structure<sup>4</sup>
                </p>
                <Button to="/moa" icon="right">
                  Review Structure/MOA
                </Button>
              </HeroFeature>
            </div>
          </div>

          <div className="container">
            <div className="hero-safety-record animate-on-enter-3">
              <Button to="/efficacy-safety/#safety-profile" icon="right">
                ROLVEDON has a proven safety profile<sup>5</sup>
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="hero-center-feature-outer-container">
        <div className="hero-center-feature-container">
          <section className="container hero-center-feature animate-on-enter-4">
            <p>
              Learn more about ROLVEDON and custom contracting options by
              talking to a ROLVEDON Representative.
            </p>
            <ContactRep kind="button" />
          </section>
        </div>
      </div>
    </div>
  );
}

function HeroFeature({ children }) {
  const [ref, inView] = useInView({ threshold: 0.25, triggerOnce: true });

  return (
    <section
      className={`hero-feature ${inView && "hero-feature--in-view"}`}
      ref={ref}
    >
      {children}
    </section>
  );
}
