import { navigate } from "gatsby-link";
import "./src/util/polyfills";

export const wrapPageElement = ({ element, props }) => {
  /**
   * Workaround for a stumper of an anchor position bug that only
   * happens on /efficacy-safety/#safety-profile. Mainly shows up on
   * Mobile Safari, even new versions. (Old versions are polyfilled.)
   *
   * Solution is to re-link to the same location after initial scroll
   * completes, which doesn't look great but gets us there. (Other solutions
   * were tried and this was the only thing found to work on ios.)
   */
  if (typeof window !== "undefined") {
    clearTimeout(window._safetyWorkaroundTimer);
    window._safetyWorkaroundTimer = undefined;
    const { hash, pathname } = window.location;
    const path = pathname + hash;
    if (
      pathname.includes("efficacy-safety") &&
      hash === "#safety-profile" &&
      path !== window._safetyWorkaroundPrevPath
    ) {
      window._safetyWorkaroundTimer = setTimeout(() => {
        window._safetyWorkaroundTimer = undefined;
        navigate(path, {
          replace: true,
        });
      }, 1000);
    }
    window._safetyWorkaroundPrevPath = path;
  }

  return element;
};
