import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React from "react";

/*
  Takes a local image filename and
  returns it as a `gatsby-image` component.

  Why? It saves all the graphql boilerplate from being
  reused everything and it's a mess to look at. So, this
  sweeps it under the rug.
*/
export default function LocalImage({ name, ...rest }) {
  const data = useStaticQuery(graphql`
    query LocalImageQuery {
      allFile(filter: { sourceInstanceName: { eq: "local-images" } }) {
        nodes {
          name
          childImageSharp {
            fluid(maxWidth: 700) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  `);

  const file = data.allFile.nodes.find((node) => node.name === name);

  return <Img fluid={file.childImageSharp.fluid} {...rest} />;
}
