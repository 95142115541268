import React from "react";

export default function ProfileFigure() {
  return (
    <>
      <ProfileFigureLarge />
      <ProfileFigureSmall />
    </>
  );
}

function ProfileFigureSmall() {
  return (
    <figure className="safety-figure-table small-only">
      <h2
        className="text-blue text-center kymi"
        style={{
          fontSize: "1.1rem",
          margin: "1rem 0",
          paddingTop: "1rem",
        }}
      >
        <span className="safety-heading-lede--purple">Secondary endpoint</span>
        Time to ANC Recovery (Mean [SD] Days) in Cycle 1*
      </h2>

      <div
        style={{
          overflowX: "auto",
        }}
      >
        <table>
          <thead>
            <tr>
              <th scope="col" colSpan="2">
                Study 1<sup>1,5</sup>
              </th>
            </tr>
            <tr>
              <th scope="col">
                Eflapegrastim&#8209;xnst
                <br />
                (n=196)
              </th>
              <th scope="col">
                Pegfilgrastim
                <br />
                (n=210)
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>3.24 (3.565)</td>
              <td>3.49 (3.589)</td>
            </tr>
          </tbody>
        </table>

        <table>
          <thead>
            <tr>
              <th scope="col" colSpan="2">
                Study 2<sup>3,5</sup>
              </th>
            </tr>
            <tr>
              <th scope="col">
                Eflapegrastim&#8209;xnst
                <br />
                (n=118)
              </th>
              <th scope="col">
                Pegfilgrastim
                <br />
                (n=119)
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>3.49 (3.723)</td>
              <td>3.35 (3.745)</td>
            </tr>
          </tbody>
        </table>
      </div>

      <h2
        className="text-blue text-center"
        style={{
          fontSize: "1.1rem",
          margin: "1rem 0",
          paddingTop: "1rem",
        }}
      >
        Depth of ANC Nadir (Mean [SD] ANC x 10<sup>9</sup>/L) in Cycle 1*
      </h2>

      <div
        style={{
          overflowX: "auto",
        }}
      >
        <table>
          <thead>
            <tr>
              <th scope="col" colSpan="2">
                Study 1<sup>5</sup>
              </th>
            </tr>
            <tr>
              <th scope="col">
                Eflapegrastim&#8209;xnst
                <br />
                (n=196)
              </th>
              <th scope="col">
                Pegfilgrastim
                <br />
                (n=210)
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>2.56 (3.086)</td>
              <td>2.53 (3.317)</td>
            </tr>
          </tbody>
        </table>

        <table>
          <thead>
            <tr>
              <th scope="col" colSpan="2">
                Study 2<sup>5</sup>
              </th>
            </tr>
            <tr>
              <th scope="col">
                Eflapegrastim&#8209;xnst
                <br />
                (n=118)
              </th>
              <th scope="col">
                Pegfilgrastim
                <br />
                (n=119)
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>2.67 (3.504)</td>
              <td>2.06 (2.034)</td>
            </tr>
          </tbody>
        </table>
      </div>

      <p
        style={{
          marginTop: "1rem",
        }}
      >
        SD=standard deviation.
      </p>

      <figcaption>
        <ul className="list-style-type-asterisk">
          <li>
            Time to ANC recovery and depth of ANC nadir were secondary
            endpoints. The differences did not meet threshold to support
            statistical significance.
          </li>
        </ul>
      </figcaption>
    </figure>
  );
}
function ProfileFigureLarge() {
  return (
    <figure className="safety-figure-table large-only">
      <h2
        className="text-blue text-center"
        style={{
          fontSize: "1.1rem",
          margin: "1rem 0",
          paddingTop: "1rem",
        }}
      >
        <span className="safety-heading-lede--purple">Secondary endpoint</span>
        Time to ANC Recovery (Mean [SD] Days) in Cycle 1*
      </h2>

      <div
        style={{
          overflowX: "auto",
        }}
      >
        <table>
          <thead>
            <tr>
              <th scope="col" colSpan="2">
                Study 1<sup>1,5</sup>
              </th>
              <th scope="col" colSpan="2">
                Study 2<sup>3,5</sup>
              </th>
            </tr>
            <tr>
              <th scope="col">
                Eflapegrastim-xnst
                <br />
                (n=196)
              </th>
              <th scope="col">
                Pegfilgrastim
                <br />
                (n=210)
              </th>
              <th scope="col">
                Eflapegrastim-xnst
                <br />
                (n=118)
              </th>
              <th scope="col">
                Pegfilgrastim
                <br />
                (n=119)
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>3.24 (3.565)</td>
              <td>3.49 (3.589)</td>
              <td>3.49 (3.723)</td>
              <td>3.35 (3.745)</td>
            </tr>
          </tbody>
        </table>
      </div>

      <h2
        className="text-blue text-center"
        style={{
          fontSize: "1.1rem",
          margin: "1rem 0",
          paddingTop: "1rem",
        }}
      >
        Depth of ANC Nadir (Mean [SD] ANC x 10<sup>9</sup>/L) in Cycle 1*
      </h2>

      <div
        style={{
          overflowX: "auto",
        }}
      >
        <table>
          <thead>
            <tr>
              <th scope="col" colSpan="2">
                Study 1<sup>5</sup>
              </th>
              <th scope="col" colSpan="2">
                Study 2<sup>5</sup>
              </th>
            </tr>
            <tr>
              <th scope="col">
                Eflapegrastim-xnst
                <br />
                (n=196)
              </th>
              <th scope="col">
                Pegfilgrastim
                <br />
                (n=210)
              </th>
              <th scope="col">
                Eflapegrastim-xnst
                <br />
                (n=118)
              </th>
              <th scope="col">
                Pegfilgrastim
                <br />
                (n=119)
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>2.56 (3.086)</td>
              <td>2.53 (3.317)</td>
              <td>2.67 (3.504)</td>
              <td>2.06 (2.034)</td>
            </tr>
          </tbody>
        </table>
      </div>

      <p
        style={{
          marginTop: "1rem",
        }}
      >
        SD=standard deviation.
      </p>

      <figcaption>
        <ul className="list-style-type-asterisk">
          <li>
            Time to ANC recovery and depth of ANC nadir were secondary
            endpoints. The differences did not meet threshold to support
            statistical significance.
          </li>
        </ul>
      </figcaption>
    </figure>
  );
}
