import { Button } from "@reusable";
import React from "react";
import clipboardSVG from "./clipboard.svg";
import computerSVG from "./computer.svg";
import moaSVG from "./moa.svg";
import neutropilSVG from "./neutropil.svg";
import shieldSVG from "./shield.svg";
import "./styles.scss";
import supportSVG from "./support.svg";
import syringeSVG from "./syringe.svg";

export default function CalloutCard({
  buttonLabel = "buttonLabel placeholder",
  text,
  icon = "icon placeholder",
  to,
  children = null,
}) {
  const Icon = () => {
    let image = getSVG(icon);

    if (image) {
      return <img src={image} alt="" />;
    }

    return null;
  };

  return (
    <div className="callout-card">
      <div className="callout-card-icon">
        <Icon />
      </div>
      {children && <div className="callout-children">{children}</div>}
      {text && <p className="callout-card-text">{text}</p>}

      <div className="callout-card-button">
        <Button icon="right" to={to}>
          {buttonLabel}
        </Button>
      </div>
    </div>
  );
}

function getSVG(icon) {
  switch (icon) {
    case "clipboard":
      return clipboardSVG;
    case "neutropil":
      return neutropilSVG;
    case "support":
      return supportSVG;
    case "syringe":
      return syringeSVG;
    case "computer":
      return computerSVG;
    case "shield":
      return shieldSVG;
    case "moa":
      return moaSVG;
    default:
      return null;
  }
}
